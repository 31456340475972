import axios from "axios";
import queryString from "query-string";
import { errorNoti } from "../../base/BasicNotifications";
import { sharedReduxSlice } from "./sharedSlice";

const { actions } = sharedReduxSlice;

const AppInstance = axios.create({ baseURL: "" });
const GLOASSARYWORDS_URL = "/GloassaryWords.json";

export const getGloassaryWords = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    AppInstance
      .get(GLOASSARYWORDS_URL)
      .then((response) => {
        resolve(response.data.data);
        dispatch(actions.setGloassaryWords(response.data.data));
      })
      .catch((err) => {
        resolve(false);
        errorNoti(err.response.data.message);
      });
  });
};
