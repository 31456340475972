import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import * as coachContactActions from "../../../Contacts/_redux/contactActions";
import InviteContactToXray from "../../../Contacts/ContactForm/InviteContactToXray";
import LoadingAnimation from "../../../../../base/LoadingAnimation";
import ContactCard from "../../../Contacts/ContactCard";
import "./invite-contact-popup.scss";
import { useDebounce } from "../../../../../base/hooks";
import StandardInput from "../../../../shared/formComponents/StandardInput/StandardInput";
import ContactForm from "../../../Contacts/ContactForm/ContactForm";
import StandardButton from "../../../../shared/formComponents/StandardButton/StandardButton";
import NoData from "../../../../shared/NoData/NoData";

const InviteContactPopup = ({
  inviteContactToggle,
  handleClose,
  setInviteContactToggle,
}) => {
  const uninvitedContacts = useSelector(
    (state) => state.coachContacts.contacts
  );
  const demoAccess = useSelector(
    (state) => state.authDetails?.userProfile?.demoAccess
  );
  const dispatch = useDispatch();

  const [showLoader, setShowLoader] = useState(true);
  const [contactToInvite, setContactToInvite] = useState(null);
  const [inviteToXrayToggle, setInviteToXrayToggle] = useState(false);
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 200);
  const [contactsToShow, setContactsToShow] = useState([]);
  const [addContactOpen, setAddContactOpen] = useState(false);

  const filterContacts = (contacts, debouncedSearchText) => {
    if (!contacts) return [];
    if (!debouncedSearchText || debouncedSearchText === "") return contacts;
    const regex = new RegExp(debouncedSearchText, "ig");
    return contacts.filter((contact) => {
      const { companyName, firstName, lastName, email } = contact;
      const name = `${firstName} ${lastName}`;
      if (name && name.match(regex)) return contact;
      if (companyName && companyName.match(regex)) return contact;
      if (email && email.match(regex)) return contact;
      return null;
    });
  };

  const getUninvitedContacts = async () => {
    try {
      setShowLoader(true);
      await dispatch(coachContactActions.getUninvitedCoachContacts());
      setShowLoader(false);
    } catch (e) {}
  };

  useEffect(() => {
    getUninvitedContacts();
  }, []);

  useEffect(() => {
    setContactsToShow(filterContacts(uninvitedContacts, debouncedSearchText));
  }, [debouncedSearchText, uninvitedContacts]);

  return (
    <>
      <Modal
        show={inviteContactToggle}
        size="xl"
        onHide={handleClose}
        fullscreen="lg-down"
        dialogClassName="invite-contact-popup"
      >
        <Modal.Header>
          <Modal.Title>Invite to X-Ray</Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          {showLoader ? (
            <LoadingAnimation />
          ) : (
            <>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <StandardInput
                  afterButtonIcon={
                    <button
                      onClick={() => setSearchText("")}
                      className="icon-text after after-icon-button"
                    >
                      {searchText === "" ? (
                        <span className="material-icons">search</span>
                      ) : (
                        <span className="material-icons">clear</span>
                      )}
                    </button>
                  }
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  placeholder="Search"
                  className="w-50 search-input-container"
                  inputClasses="search-input"
                />
                <StandardButton
                  text="Add Contact"
                  icon={
                    <span className="material-icons position-relative">
                      add
                    </span>
                  }
                  color="btn-success"
                  className="btn-sm"
                  reversed
                  textClassName="m-0"
                  onClick={() => {
                    setAddContactOpen(true);
                    setInviteContactToggle(false);
                  }}
                />
              </div>
              {!uninvitedContacts || uninvitedContacts.length === 0 ? (
                <NoData
                  style={{ height: "70vh", border: "none" }}
                  title="No new contacts to X-Ray"
                  content="Please add a contact"
                />
              ) : (
                <ul className="contact-list">
                  {contactsToShow &&
                    contactsToShow.map((contact, index) => (
                      <li key={`contact-${index}`}>
                        <ContactCard
                          contact={contact}
                          cardActionOnClick={() => {
                            setContactToInvite(contact);
                            setInviteToXrayToggle(true);
                            setInviteContactToggle(false);
                          }}
                        />
                      </li>
                    ))}
                </ul>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
      <InviteContactToXray
        contact={contactToInvite}
        open={inviteToXrayToggle}
        handleCancel={() => {
          setContactToInvite(null);
          setInviteToXrayToggle(false);
          setInviteContactToggle(true);
        }}
        handleOK={() => {
          setContactToInvite(null);
          setInviteToXrayToggle(false);
          handleClose();
        }}
        demoAccess={demoAccess}
      />
      <ContactForm
        contactInfo={null}
        open={addContactOpen}
        setOpen={setAddContactOpen}
        handleContactClose={() => {
          setAddContactOpen(false);
          setInviteContactToggle(true);
        }}
        getCoachContacts={getUninvitedContacts}
      />
    </>
  );
};

export default InviteContactPopup;
