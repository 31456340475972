import React, { useEffect, useState } from "react";
import { Modal, ButtonGroup } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import StandardButton from "../../../shared/formComponents/StandardButton/StandardButton";
import StandardInput from "../../../shared/formComponents/StandardInput/StandardInput";
import StandardIntlPhoneInput from "../../../shared/formComponents/StandardIntlPhoneInput/StandardIntlPhoneInput";
import "./contact-form.scss";
import * as coachContactsActions from "../_redux/contactActions";
import * as xrayActions from "../../Xray/_redux/xrayActions";
import { QUEST, XRAY_USER_SCORE } from "../../../../data/RouteUrls";
import ConfirmationPopup from "../../../shared/ConfirmationPopup/ConfirmationPopup";
import InviteContactToXrayPopup from "./InviteContactToXray";
import validationUtils from "../../../../utils/validationUtils";
import { ContactValidation } from "../../../../validations/Contact";
import { copyToClipboard } from "../../../../utils/generalUtils";
import {
  isContactInvited,
  isContactRegistered,
  isContactXrayCompleted,
  isContactXrayStarted,
} from "../../../../utils/contactUtils";
import ProgressCardPopup from "../ProgressCardPopup/ProgressCardPopup";
import { CONTACT_XRAY_STATE_ENUMS } from "../../../../data/enums/XrayStateEnums";

const ContactForm = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const validations = ContactValidation;

  const {
    contactInfo,
    open,
    setOpen,
    getCoachContacts,
    handleContactClose,
    demoAccess,
  } = props;
  const isCurrentInvited = isContactInvited(contactInfo?.status);
  const isCurrentRegistered = isContactRegistered(contactInfo?.status);
  const isCurrentXrayCompleted = isContactXrayCompleted(contactInfo?.status);
  const isCurrentXrayStarted = isContactXrayStarted(contactInfo?.status);
  const [inviteToggle, setInviteToggle] = useState(false);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(false);
  const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false);

  const [edit, setEdit] = useState(contactInfo ? false : true);
  const [showProgressView, setShowProgressView] = useState({
    open: false,
    userId: null,
  });

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [showError, setShowError] = useState(false);
  const [showXrayJourney, setShowXrayJourney] = useState(false);

  const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNo("");
    setCompanyName("");
    setInviteToggle(false);
    setSaveBtnDisabled(false);
    setShowError(false);
    setShowXrayJourney(false);
    handleContactClose();
  };

  const saveContact = async (data, type = "add") => {
    if (
      type !== "delete" &&
      !validationUtils.checkFormValidity(data, validations)
    ) {
      setShowError(true);
      return;
    }
    setSaveBtnDisabled(true);
    try {
      let saveContactSuccess;
      const coachCeoId = data.id;
      delete data.status;
      if (type === "add") {
        // add contact
        delete data.id;
        saveContactSuccess = await dispatch(
          coachContactsActions.addCoachContact(data)
        );
      } else if (type === "edit") {
        // edit contact
        saveContactSuccess = await dispatch(
          coachContactsActions.editCoachContact(data)
        );
      } else if (type === "delete") {
        // delete contact
        saveContactSuccess = await dispatch(
          coachContactsActions.deleteCoachContact(coachCeoId)
        );
      }
      if (saveContactSuccess) {
        handleClose();
      }
      if (getCoachContacts) {
        getCoachContacts();
      }
    } catch (err) {
    } finally {
      setSaveBtnDisabled(false);
    }
  };

  useEffect(() => {
    setEdit(contactInfo ? false : true);
  }, [contactInfo]);

  useEffect(() => {
    setFirstName(contactInfo?.firstName || "");
    setLastName(contactInfo?.lastName || "");
    setEmail(contactInfo?.email || "");
    setPhoneNo(contactInfo?.phoneNo || "");
    setCompanyName(contactInfo?.companyName || "");
  }, [contactInfo, edit]);

  useEffect(() => {
    const timeout = setTimeout(() => setShowCopiedTooltip(false), 1000);
    return () => clearTimeout(timeout);
  }, [showCopiedTooltip]);

  const getData = () => {
    const data = {
      ...contactInfo,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      email,
      phoneNo,
      companyName,
    };
    delete data.inviteCode;
    return data;
  };

  const redirectToXrayScore = (asContacts) => {
    dispatch(
      xrayActions.updateXrayState({
        surveyId: asContacts.surveyId,
        userId: asContacts.userId,
        respondantsEdited: asContacts.isEditRespondant ?? false,
        recommendationsEdited: asContacts.isEditRecomm ?? false,
        xrayPublished: asContacts.isSentResult ?? false,
        contact: { ...asContacts },
      })
    );
    // history.push(XRAY_USER_SCORE.replace(`:userId`, asContacts.userId));
    window.location.href = XRAY_USER_SCORE.replace(
      `:userId`,
      asContacts.userId
    );
  };

  const redirectToQuest = (asContacts) => {
    dispatch(
      xrayActions.updateXrayState({
        surveyId: asContacts.surveyId,
        userId: asContacts.userId,
        companyName: asContacts.companyName,
        respondantsEdited: asContacts.isEditRespondant ?? false,
        recommendationsEdited: asContacts.isEditRecomm ?? false,
        xrayPublished: asContacts.isSentResult ?? false,
        contact: { ...asContacts },
      })
    );
    history.push({pathname: QUEST, search: `?id=${asContacts.userId}`});
  }

  function renderContactInfo() {
    return (
      <div key="contact-form">
        <div>
          {contactInfo?.originalMail &&
            contactInfo?.originalMail !== contactInfo?.email ? (
            <p className="original-mail">
              Originally invited on {contactInfo?.originalMail}
            </p>
          ) : null}
          <div className="field-card">
            <div className="field">
              <div className="label">
                <div className="label-img">
                  <img src="/assets/images/email.svg" alt="Email" />
                </div>
                Email
              </div>
              <div className="value">{contactInfo?.email || "NA"}</div>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="field-card">
            <div className="field">
              <div className="label">
                <div className="label-img">
                  <img src="/assets/images/user_formals.svg" alt="User" />
                </div>
                First Name
              </div>
              <div className="value">{contactInfo?.firstName || "NA"}</div>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="field-card">
            <div className="field">
              <div className="label">
                <div className="label-img">
                  <img src="/assets/images/user_formals.svg" alt="User" />
                </div>
                Last Name
              </div>
              <div className="value">{contactInfo?.lastName || "NA"}</div>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="field-card">
            <div className="field">
              <div className="label">
                <div className="label-img">
                  <img src="/assets/images/company_icon.svg" alt="Company" />
                </div>
                Company
              </div>
              <div className="value">{contactInfo?.companyName || "NA"}</div>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <div className="field-card">
            <div className="field">
              <div className="label">
                <div className="label-img">
                  <img src="/assets/images/phone.svg" alt="Phone" />
                </div>
                Contact No.
              </div>
              <div className="value">{contactInfo?.phoneNo || "NA"}</div>
            </div>
          </div>
        </div>
        <div className="mt-4 field-card xray-journey">
          <div className="field">
            <div className="label">
              <div className="label-img">
                <span className="material-icons">today</span>
              </div>
              {contactInfo?.feed?.length > 0 ? contactInfo.feed[0]?.feed : null}
            </div>
            {contactInfo?.feed?.length > 1 ? (
              <StandardButton
                className="p-1"
                onClick={() => setShowXrayJourney((prev) => !prev)}
                icon={
                  <span className="material-icons">
                    {!showXrayJourney ? "expand_more" : "expand_less"}
                  </span>
                }
              />
            ) : null}
          </div>
          {showXrayJourney &&
            contactInfo?.feed?.slice(1).map((journey, idx) => {
              return (
                <div className="field journey-field" key={`journey-${idx}`}>
                  <div className="label">{journey.feed}</div>
                </div>
              );
            })}
        </div>
      </div>
    );
  }

  function renderContactForm() {
    return (
      <div key="contact-form">
        <div>
          <StandardInput
            placeholder="example@mail.com"
            value={email}
            label="Email"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            showError={showError}
            validations={validations.email}
            disabled={!edit}
          />
        </div>
        <div className="mt-4">
          <StandardInput
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            showError={showError}
            validations={validations.firstName}
            disabled={!edit}
          />
        </div>
        <div className="mt-4">
          <StandardInput
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            showError={showError}
            validations={validations.lastName}
            disabled={!edit}
          />
        </div>
        <div className="mt-4">
          <StandardInput
            value={companyName}
            label="Company Name"
            type="text"
            validations={validations.companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            showError={showError}
            disabled={!edit}
          />
        </div>
        <div className="mt-4">
          <StandardIntlPhoneInput
            label="Phone Number"
            value={phoneNo}
            onChange={(value) => setPhoneNo(value)}
            showError={showError}
            disabled={!edit}
          />
        </div>
      </div>
    );
  }

  function RenderModalActions() {
    if (edit) {
      return (
        <>
          <StandardButton
            text="Cancel"
            onClick={() => {
              if (contactInfo) {
                setEdit(false);
              } else {
                // setOpen(false);
                handleClose();
              }
            }}
            className="btn-sm"
          />
          <StandardButton
            text="Save"
            color="btn-success"
            className="btn-sm"
            onClick={() => {
              saveContact(getData(), contactInfo ? "edit" : "add");
            }}
            disabled={saveBtnDisabled}
          />{" "}
        </>
      );
    } else
      return (
        <>
          {!isCurrentRegistered ? (
            <>
              <StandardButton
                text="Edit"
                onClick={() => setEdit(true)}
                className="btn-sm flex-fill"
                color="btn-outline-dark"
              />
              <StandardButton
                text="Delete"
                onClick={() => {
                  setShowConfirmDeletePopup(true);
                  setOpen(false);
                }}
                className="btn-sm flex-fill"
                color="btn-outline-danger"
              />
            </>
          ) : null}
          <ButtonGroup className="flex-fill">
            <StandardButton
              text={
                isCurrentXrayCompleted
                  ? `View X-Ray`
                  : isCurrentXrayStarted
                    ? "View Progress"
                    : isCurrentRegistered
                      ? `Registered`
                      : isCurrentInvited
                        ? "Resend Invite"
                        : "Invite to X-Ray"
              }
              onClick={() => {
                if (isCurrentXrayCompleted) {
                  redirectToXrayScore(contactInfo);
                } else if (isCurrentRegistered) {
                  setOpen(false);
                  setShowProgressView({
                    open: true,
                    userId: contactInfo?.userId,
                    surveyId: contactInfo?.surveyId,
                  });
                } else {
                  setOpen(false);
                  setInviteToggle(true);
                }
              }}
              className="btn-sm"
              color={
                contactInfo?.status === CONTACT_XRAY_STATE_ENUMS.REGISTERED
                  ? "btn-disabled"
                  : "btn-success"
              }
              disabled={
                contactInfo?.status === CONTACT_XRAY_STATE_ENUMS.REGISTERED
              }
            />
            {isCurrentInvited && !isCurrentRegistered ? (
              <div className="position-relative">
                <StandardButton
                  color="btn-success"
                  className="btn-sm position-relative"
                  textClassName="m-0"
                  style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                  icon={
                    <span
                      className="material-icons"
                      style={{ fontSize: "inherit" }}
                    >
                      content_copy
                    </span>
                  }
                  onClick={() => {
                    setShowCopiedTooltip(true);
                    copyToClipboard(
                      `${process.env.REACT_APP_SME_APP_URL}auth/signup?inviteCode=${contactInfo?.inviteCode}`
                    );
                  }}
                />
                <div
                  className={`position-absolute copied-tooltip ${showCopiedTooltip ? "show" : ""
                    }`}
                >
                  Copied!
                </div>
              </div>
            ) : null}
          </ButtonGroup>
          {props.contactInfo?.status !== "INVITED"?<StandardButton text="View Goals and Rhythms Progress"
            color="btn-success"
            className="btn-sm flex-fill"
            onClick={()=>redirectToQuest(contactInfo)}
          />:null}
        </>
      );
  }

  function renderTitle() {
    if (edit) {
      if (contactInfo) return "Edit Contact";
      return "Add Contact Details";
    } else if (contactInfo) return "Contact Details";
  }

  return (
    <>
      <Modal
        show={open}
        onHide={handleClose}
        dialogClassName="add-contact"
        fullscreen="sm-down"
        size="md"
        centered
        scrollable
      >
        <Modal.Header>
          <Modal.Title className="modal-title d-flex mx-auto">
            {renderTitle()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="py-0">
          {edit ? renderContactForm() : renderContactInfo()}
        </Modal.Body>
        <Modal.Footer className="p-3">
          <RenderModalActions />
        </Modal.Footer>
      </Modal>
      <ConfirmationPopup
        isOpen={showConfirmDeletePopup}
        title="Are you sure you want to delete this contact?"
        content={
          isCurrentInvited
            ? `${contactInfo?.firstName} will not be able to signup and take the X-ray`
            : null
        }
        onCancel={() => {
          setOpen(true);
          setShowConfirmDeletePopup(false);
        }}
        onConfirm={() => {
          setShowConfirmDeletePopup(false);
          saveContact(getData(), "delete");
        }}
      />
      <InviteContactToXrayPopup
        open={inviteToggle}
        handleCancel={() => {
          setInviteToggle(false);
          setOpen(true);
          getCoachContacts();
          handleContactClose();
        }}
        contact={contactInfo}
        demoAccess={demoAccess}
      />
      <ProgressCardPopup
        open={showProgressView.open}
        surveyId={showProgressView.surveyId}
        userId={showProgressView.userId}
        handleCancel={() => {
          setShowProgressView({ open: false, userId: null });
          setOpen(true);
        }}
        companyName={contactInfo?.companyName}
      />
    </>
  );
};

export default ContactForm;
