import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  gloassaryWords: [],
};

export const sharedReduxSlice = createSlice({
  name: "sharedReduxSlice",
  initialState: initialState,
  reducers: {
    setGloassaryWords: (state, actions) => {
      state.gloassaryWords = actions.payload;
    },
  },
});
